import { create } from "zustand"

type Store = {
  personModalIsOpen: boolean
  setPersonModalIsOpen: (value: boolean) => void
}

export const usePersonModalStore = create<Store>((set) => ({
  personModalIsOpen: false,
  setPersonModalIsOpen: (value) => set(() => ({ personModalIsOpen: value })),
}))
