import useScreenSize from "@/hooks/useScreenSize"
import { getAssetsPath } from "@/utils/getAssetsPathUtils"
import { Stack } from "@mui/material"
import Image from "next/image"

export function CreateAccountImage() {
  const { isUpMd } = useScreenSize()
  return (
    <Stack>
      {isUpMd && (
        <Image
          src={getAssetsPath() + "/img/banner-createaccount.svg"}
          alt="logo do governo de mato grosso do sul"
          width={0}
          height={0}
          style={{ width: "100%", height: "100%" }}
        />
      )}

      {!isUpMd && (
        <Image
          src={getAssetsPath() + "/img/banner-createaccount-mobile.svg"}
          alt="logo do governo de mato grosso do sul"
          width={0}
          height={0}
          style={{ width: "100%", height: "100%" }}
        />
      )}
    </Stack>
  )
}
