import * as yup from "yup"

export const loginSchema = yup.object().shape({
  cpf: yup.string().required("CPF é necessário para realizar login"),
  password: yup
    .string()
    .test("password-required", "Senha é um campo obrigatório", function (value) {
      const context = this.options.context || {}
      const isPasswordRecovery = context.isPasswordRecovery || false
      if (!isPasswordRecovery) {
        return !!value
      }
      return true
    }),
})
