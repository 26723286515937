"use client"

import { Typography } from "@mui/material"

import packageJson from "../../../../package.json"

interface VersionProps {
  prefix?: string
}

export default function Version({ prefix }: VersionProps) {
  const version = packageJson.version

  if (prefix) {
    return (
      <Typography sx={{ fontSize: "small" }}>
        {prefix} {version}
      </Typography>
    )
  }

  return (
    <Typography sx={{ fontSize: "x-small", textAlign: "right", marginTop: "1rem" }}>
      {version}
    </Typography>
  )
}
