"use client"

import { ModalComponent } from "@/components/ui/modal/Modal"
import React from "react"

import { PrivacyContent } from "./PrivacyContent"
import { TermsUseContent } from "./TermsUseContent"

interface ModalProps {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
}

export const TermsModal = ({ isOpen, setIsOpen }: ModalProps) => (
  <ModalComponent isOpen={isOpen} setIsOpen={setIsOpen}>
    <ModalComponent.Header title="Termo de Política de Segurança e Privacidade" />
    <ModalComponent.Content
      hasBackButton={true}
      hasActionButton={false}
      handleBackButton={() => setIsOpen(false)}
    >
      <TermsUseContent />
    </ModalComponent.Content>
  </ModalComponent>
)

export const PrivacyModal = ({ isOpen, setIsOpen }: ModalProps) => (
  <ModalComponent isOpen={isOpen} setIsOpen={setIsOpen}>
    <ModalComponent.Header title="Política de Privacidade do MS Qualifica Digital" />
    <ModalComponent.Content
      hasBackButton={true}
      hasActionButton={false}
      handleBackButton={() => setIsOpen(false)}
    >
      <PrivacyContent />
    </ModalComponent.Content>
  </ModalComponent>
)
