import useScreenSize from "@/hooks/useScreenSize"
import { getAssetsPath } from "@/utils/getAssetsPathUtils"
import { Stack } from "@mui/material"
import Image from "next/image"

export function LoginImage() {
  const { isUpMd } = useScreenSize()
  return (
    <Stack>
      {isUpMd && (
        <Image
          src={getAssetsPath() + "/img/banner-login-modal.webp"}
          alt="logo do governo de mato grosso do sul"
          width={270}
          height={450}
          style={{ borderRadius: "16px" }}
        />
      )}

      {!isUpMd && (
        <Image
          src={getAssetsPath() + "/img/banner-login-modal-mobile.webp"}
          alt="logo do governo de mato grosso do sul"
          width={330}
          height={130}
          style={{ borderRadius: "16px" }}
        />
      )}
    </Stack>
  )
}
