import { ROUTES } from "@/interfaces/constants/routes"
import { useCurrentProfileStore } from "@/stores/data/useCurrentProfileStore"
import {
  isCandidateProfileRole,
  isCompanyProfileRole,
  isFuntrabProfileRole,
  isSemadescProfileRole,
} from "@/utils/profileUtils"

export async function selectPathnameService(redirect?: string) {
  if (redirect) {
    return redirect
  }

  const { currentProfile } = useCurrentProfileStore.getState()

  if (!currentProfile) {
    return ROUTES.HOME
  }

  if (isSemadescProfileRole(currentProfile)) {
    return ROUTES.PAINEL.SEMADESC
  } else if (isFuntrabProfileRole(currentProfile)) {
    return ROUTES.PAINEL.FUNTRAB
  } else if (isCompanyProfileRole(currentProfile)) {
    return ROUTES.PAINEL.COMPANY
  } else if (isCandidateProfileRole(currentProfile)) {
    return ROUTES.PAINEL.CANDIDATE
  }

  return ROUTES.HOME
}
