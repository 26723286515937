import { ModalContent } from "@/components/ui/modal/ModalContent"
import { ModalHeader } from "@/components/ui/modal/ModalHeader"
import { useStatusStepperContext } from "@/stores/ui/useStatusStepperStore"
import { Fade, Stack, StackProps, SxProps } from "@mui/material"
import Modal from "@mui/material/Modal"
import * as React from "react"
import { ReactNode } from "react"

interface ModalProps extends StackProps {
  children?: ReactNode
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  handleBackButton?: () => void
  onClickOutside?: () => void
  sx?: SxProps
}

export function ModalComponent({
  children,
  isOpen,
  setIsOpen,
  onClickOutside,
  sx,
  ...rest
}: ModalProps) {
  const { resetCurrentStep } = useStatusStepperContext()
  const handleClose = () => {
    if (onClickOutside) {
      onClickOutside()
    } else {
      setIsOpen(false)
    }
    resetCurrentStep()
  }

  return (
    <Modal open={isOpen} onClose={handleClose} closeAfterTransition>
      <Fade in={isOpen}>
        <Stack
          direction={{ sm: "row" }}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            minWidth: { xs: "100%", sm: 0 },
            minHeight: { xs: "100%", sm: "50%", lg: "400px" },
            maxWidth: { xs: "100%", sm: "95%" },
            maxHeight: { xs: "100%", sm: "90%", lg: "80vh" },
            backgroundColor: "white",
            borderRadius: {
              xs: 0,
              sm: 1,
            },
            outline: "none",
            ...sx,
          }}
          {...rest}
        >
          {children}
        </Stack>
      </Fade>
    </Modal>
  )
}

ModalComponent.Header = ModalHeader
ModalComponent.Content = ModalContent
