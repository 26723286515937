import { roboto } from "@/styles/fonts"
import Typography from "@mui/material/Typography"
import * as React from "react"

interface AccessModalTitleTextProps {
  text: string
}

export function AccessModalTitleText({ text }: AccessModalTitleTextProps) {
  return (
    <Typography
      sx={{
        textAlign: "center",
        fontFamily: roboto.style.fontFamily,
        fontWeight: 400,
        fontSize: "2.25rem",
        color: "black",
        wordWrap: "break-word",
      }}
    >
      {text}
    </Typography>
  )
}
