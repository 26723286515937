import React, { ReactNode, createContext, useContext, useState } from "react"

interface StatusStepperContextType {
  currentStep: number
  setCurrentStep: (value: number) => void
  nextStep: () => void
  prevStep: () => void
  resetCurrentStep: () => void
}

const initialContext: StatusStepperContextType = {
  currentStep: 0,
  setCurrentStep: () => {},
  nextStep: () => {},
  prevStep: () => {},
  resetCurrentStep: () => {},
}

const StatusStepperContext = createContext<StatusStepperContextType>(initialContext)

interface StatusStepperProviderProps {
  children: ReactNode
  initialStep?: number
}

export const StatusStepperProvider: React.FC<StatusStepperProviderProps> = ({
  children,
  initialStep = 0,
}) => {
  const [currentStep, setCurrentStep] = useState<number>(initialStep)

  const nextStep = () => setCurrentStep((prev) => prev + 1)
  const prevStep = () => setCurrentStep((prev) => prev - 1)
  const resetCurrentStep = () => setCurrentStep(0)

  return (
    <StatusStepperContext.Provider
      value={{ currentStep, setCurrentStep, nextStep, prevStep, resetCurrentStep }}
    >
      {children}
    </StatusStepperContext.Provider>
  )
}

export const useStatusStepperContext = () => {
  const context = useContext(StatusStepperContext)
  if (!context) {
    throw new Error("useStatusStepper must be used within a StatusStepperProvider")
  }
  return context
}
