import { Stack, SxProps, Typography, useTheme } from "@mui/material"

interface ModalHeaderProps {
  children?: React.ReactNode
  title?: string
  sx?: SxProps
}

export function ModalHeader({ title, children, sx }: ModalHeaderProps) {
  const theme = useTheme()
  return (
    <Stack
      direction={{ xs: "row", sm: "column" }}
      justifyContent="flex-start"
      sx={{
        borderRadius: {
          xs: 0,
          sm: "1rem 0 0 1rem",
        },
        backgroundColor: theme.palette.primary.main,
        minHeight: {
          xs: "auto",
          sm: "100%",
        },
        minWidth: {
          xs: "100%",
          sm: "auto",
        },
        maxWidth: {
          sm: "30%",
          md: "25%",
          lg: "20%",
          xl: "15%",
        },
        padding: "1rem",
        textOverflow: "ellipsis",
        ...sx,
      }}
    >
      {children}

      {title && (
        <Typography
          variant="h6"
          sx={{
            color: "white",
            textWrap: "pretty",
          }}
        >
          {title}
        </Typography>
      )}
    </Stack>
  )
}
