import { userRoleConsts } from "@/interfaces/constants/UserRoleConsts"
import { UserRoles } from "@/interfaces/enums/UserRoles"
import { ProfileOutput } from "@/interfaces/profile/ProfileOutput"
import { ProfilesOutput } from "@/interfaces/profile/ProfilesOutput"
import { getProfileService } from "@/services/profile/getProfileService"
import { useCurrentProfileStore } from "@/stores/data/useCurrentProfileStore"

export async function updateProfileAndPersonService() {
  const { setCurrentProfile, setCurrentPerson } = useCurrentProfileStore.getState()
  const profiles: ProfilesOutput = await getProfileService() // Garantir o tipo correto

  if (profiles.profiles.length === 0) {
    throw new Error("Nenhum perfil encontrado")
  }

  // Função para obter o peso de um perfil
  const getProfileWeight = (profile: ProfileOutput): number => {
    const role = profile.role.value as UserRoles
    return userRoleConsts[role]?.weight ?? 0
  }

  // Encontrar o perfil com maior peso
  const highestWeightedProfile = profiles.profiles.reduce(
    (prev: ProfileOutput, current: ProfileOutput) => {
      return getProfileWeight(current) > getProfileWeight(prev) ? current : prev
    },
  )

  setCurrentProfile(highestWeightedProfile)
  setCurrentPerson(profiles.person)
}
