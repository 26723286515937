import { ModalComponent } from "@/components/ui/modal/Modal"
import { CreateAccountImage } from "@/containers/person/login/CreateAccountImage"
import { CreateYourFreeAccount } from "@/containers/person/login/CreateYourFreeAccount"
import { Login } from "@/containers/person/login/Login"
import { LoginImage } from "@/containers/person/login/LoginImage"
import { useLoginModalStore } from "@/stores/ui/useLoginModalStore"
import { Tabs } from "@mui/material"
import * as React from "react"
import { useEffect, useState } from "react"

import { AccessTab } from "./AccessTab"

export default function AccessModal() {
  const { loginModalIsOpen, setLoginModalIsOpen, initialTab } = useLoginModalStore()
  const [selectedTab, setSelectedTab] = useState(initialTab)

  useEffect(() => {
    setSelectedTab(initialTab)
  }, [initialTab])

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue)
  }

  const handleClose = () => {
    setLoginModalIsOpen(false)
  }

  return (
    <ModalComponent
      isOpen={loginModalIsOpen}
      setIsOpen={setLoginModalIsOpen}
      sx={{
        width: { xs: "95%", sm: "95%", md: "90%", lg: "90%" },
        height: "90vh",
        maxWidth: "800px",
        maxHeight: {
          md: "650px",
        },
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          TabIndicatorProps={{ style: { background: "transparent" } }}
          sx={{
            background: `linear-gradient(170deg, #13509C 0%, #1C5DA1 7%, #367FAE 20%, #3EAFB2 51%, #3EAFB2 77%)`,
            height: "auto",
            width: "auto",
            borderTopRightRadius: {
              xs: 0,
              md: 16,
            },
            borderTopLeftRadius: {
              xs: 0,
              md: 16,
            },
          }}
        >
          <AccessTab label="ENTRAR" />
          <AccessTab label="CRIE SUA CONTA" />
        </Tabs>
        {selectedTab === 0 ? (
          <Login banner={<LoginImage />} onClose={handleClose} />
        ) : (
          <CreateYourFreeAccount banner={<CreateAccountImage />} />
        )}
      </div>
    </ModalComponent>
  )
}
