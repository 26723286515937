import { EnumData } from "@/interfaces/EnumData"
import { IdentificationType } from "@/interfaces/enums/IdentificationType"
import { cnpj as cnpjValidator, cpf as cpfValidator } from "cpf-cnpj-validator"

export function verifyCPF(cpf: string) {
  return cpfValidator.isValid(cpf)
}

export function verifyCNPJ(cnpj: string) {
  return cnpjValidator.isValid(cnpj)
}

export function unmaskIdentificationNumber(identificationNumber: string) {
  if (identificationNumber) {
    return identificationNumber
      .replaceAll(".", "")
      .replaceAll("-", "")
      .replaceAll("/", "")
  } else {
    return identificationNumber
  }
}

export function verifyIdentificationNumber(value: string) {
  return verifyCPF(value) || verifyCNPJ(value)
}

export function maskFormat(identificationType: string) {
  if (identificationType === "cpf") {
    return cpfMask()
  } else if (identificationType === "cnpj") {
    return cnpjMask()
  } else {
    return cnpjMask()
  }
}

export function getPlaceholder(identificationType: string) {
  switch (identificationType) {
    case IdentificationType.CPF:
      return "000.000.000-00"
    case IdentificationType.CNPJ:
      return "00.000.000/0000-00"
    case IdentificationType.CEI:
      return "00.000.00000/000"
    default:
      return "00.000.000/0000-00"
  }
}

export function cpfMask() {
  return { mask: "___.___.___-__", replacement: { _: /\d/ } }
}

export function cnpjMask() {
  return { mask: "__.___.___/____-__", replacement: { _: /\d/ } }
}

export function ceiMask() {
  return { mask: "__.___._____/___", replacement: { _: /\d/ } }
}

export function maskCpf(cpf: string | null) {
  if (cpf) {
    return cpf
      .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{3})(\d)/, "$1.$2") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1") // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
  } else {
    return cpf
  }
}

export function maskCNPJ(cnpj: string) {
  if (cnpj) {
    return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5")
  } else return cnpj
}

export function maskIdentificationNumber(
  identificationType: EnumData,
  identificationNumber: string,
) {
  switch (identificationType.value) {
    case IdentificationType.CPF:
      return maskCpf(identificationNumber)
    case IdentificationType.CNPJ:
      return maskCNPJ(identificationNumber)
    // TODO: Criar função de máscara de CEI
    case IdentificationType.CEI:
      return identificationNumber
    default:
      return identificationNumber
  }
}
