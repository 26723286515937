import { FormTextField } from "@/components/ui/input/FormTextField"
import { MessageSnackbar } from "@/components/ui/message/MessageSnackbar"
import { ModalComponent } from "@/components/ui/modal/Modal"
import { Recaptcha } from "@/components/ui/recaptcha/Recaptcha"
import { AccessModalBodyText } from "@/containers/person/login/AccessModalBodyText"
import { AccessModalContent } from "@/containers/person/login/AccessModalContent"
import { AccessModalTitleText } from "@/containers/person/login/AccessModalTitleText"
import { LoginInput } from "@/interfaces/login/LoginInput"
import { PasswordRecoveryInput } from "@/interfaces/login/PasswordRecoveryInput"
import { loginSchema } from "@/schemas/person/login/loginSchema"
import { loginService } from "@/services/login/loginService"
import { passwordRecoveryService } from "@/services/login/passwordRecoveryService"
import { selectPathnameService } from "@/services/profile/selectPathnameService"
import { updateProfileAndPersonService } from "@/services/profile/updateProfileAndPersonService"
import { encryptPassword } from "@/utils/CryptoUtils"
import { isDevelopmentMode } from "@/utils/environmentUtils"
import { cpfMask } from "@/utils/identificationNumberUtils"
import { yupResolver } from "@hookform/resolvers/yup"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import { LoadingButton } from "@mui/lab"
import { Alert, Box, Button, Grid, Link, Stack, Typography } from "@mui/material"
import { useMask } from "@react-input/mask"
import { useRouter, useSearchParams } from "next/navigation"
import * as React from "react"
import { useState } from "react"
import { FormProvider, useForm } from "react-hook-form"

interface LoginProps {
  banner: React.ReactNode
  onClose: () => void
}

export function Login({ banner, onClose }: LoginProps) {
  const [captchaIsDone, setCaptchaIsDone] = useState<boolean>(isDevelopmentMode())
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [isPasswordRecovery, setIsPasswordRecovery] = useState<boolean>(false)
  const [successMessage, setSuccessMessage] = useState<string | null>(null)
  const searchParams = useSearchParams()
  const methods = useForm({
    resolver: yupResolver(loginSchema),
    context: { isPasswordRecovery },
  })

  const router = useRouter()
  const redirect = searchParams.get("redirect")

  const handleClose = () => {
    setIsPasswordRecovery(false)
    setSuccessMessage(null)
    setErrorMessage(null)
    methods.reset()
    onClose()
  }

  const onSubmit = methods.handleSubmit(async (data) => {
    setIsLoading(true)
    setErrorMessage(null)
    try {
      if (isPasswordRecovery) {
        const recoveryData: PasswordRecoveryInput = { cpf: data.cpf }
        await passwordRecoveryService(recoveryData)
        setSuccessMessage(
          "Instruções de recuperação de senha foram enviadas para o seu e-mail.",
        )
      } else {
        if (data.password) data.password = encryptPassword(data.password)
        const loginResponse = await loginService(data as LoginInput)
        if (loginResponse) {
          await updateProfileAndPersonService()
          const pathname = await selectPathnameService(redirect ?? undefined)
          if (pathname) {
            router.push(pathname)
          }
          handleClose()
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage(error.message)
      }
    } finally {
      setIsLoading(false)
      methods.reset({ cpf: data.cpf, password: "" })
    }
  })

  const isFormValid =
    methods.watch("cpf") &&
    (isPasswordRecovery || methods.watch("password")) &&
    captchaIsDone

  const formContent = (
    <form onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormTextField
            name={"cpf"}
            label={"CPF"}
            id="btn-login-cpf"
            inputRef={useMask(cpfMask())}
            placeholder={"___.___.___-__"}
            type={"text"}
            autoComplete={isPasswordRecovery ? "off" : "on"}
            tooltip="Informe o número do CPF"
          />
        </Grid>

        {!isPasswordRecovery && (
          <Grid item xs={12}>
            <FormTextField
              helperText={
                <Link
                  onClick={() => {
                    setIsPasswordRecovery(true)
                    methods.setValue("password", "")
                  }}
                  sx={{
                    color: "info.main",
                    fontSize: "0.75rem",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  Esqueci minha senha
                </Link>
              }
              name="password"
              id="btn-login-password"
              label={"Senha"}
              type={"password"}
              tooltip="Informe a sua senha"
            />
          </Grid>
        )}

        {!isDevelopmentMode() && (
          <Grid item xs={12}>
            <Recaptcha
              captchaIsDone={captchaIsDone}
              setCaptchaIsDone={setCaptchaIsDone}
            />
          </Grid>
        )}

        <Grid
          item
          xs={12}
          alignItems={"center"}
          justifyContent={"center"}
          alignSelf={"center"}
        >
          <Stack direction="row" spacing={2} alignItems={"center"}>
            {isPasswordRecovery && (
              <Button variant="text" onClick={() => setIsPasswordRecovery(false)}>
                Voltar para Login
              </Button>
            )}
            <LoadingButton
              color={"primary"}
              variant={"contained"}
              endIcon={<ChevronRightIcon />}
              loading={isLoading}
              disabled={!isFormValid}
              type="submit"
            >
              {isPasswordRecovery ? "RECUPERAR SENHA" : "FAZER LOGIN"}
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </form>
  )

  return (
    <ModalComponent.Content hasActionButton={false} hasBackButton={false}>
      <AccessModalContent banner={banner} reversed={true}>
        <AccessModalTitleText
          text={isPasswordRecovery ? "Recuperar Senha" : "Bem-vindo"}
        />
        {!successMessage && (
          <AccessModalBodyText
            text={
              isPasswordRecovery
                ? "Por favor, insira seu CPF para recuperar sua senha."
                : "Busca e oferta de empregos, qualificação profissional e acesso a crédito para microempresas. Tudo em uma única plataforma."
            }
          />
        )}
        <FormProvider {...methods}>
          {successMessage ? (
            <Box
              rowGap={2}
              sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
            >
              <Alert>
                <Typography sx={{ whiteSpace: "pre-line" }}>{successMessage}</Typography>
              </Alert>

              <Alert severity="warning">
                <Typography>ATENÇÃO: O tempo para troca de senha é limitado!</Typography>
              </Alert>

              <Button variant="contained" color="primary" onClick={handleClose}>
                Fechar
              </Button>
            </Box>
          ) : (
            formContent
          )}
        </FormProvider>
        {errorMessage && (
          <MessageSnackbar
            show={Boolean(errorMessage)}
            message={errorMessage}
            severity="error"
          />
        )}
      </AccessModalContent>
    </ModalComponent.Content>
  )
}
