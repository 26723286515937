"use client"

import { Alert, AlertColor, Snackbar } from "@mui/material"
import React, { useEffect, useState } from "react"

interface MessageSnackbarProps {
  show: boolean
  message: string
  severity: AlertColor | undefined
}

// TODO: Não utilizar esse componente
// É preciso refatorar pra remover ele e remover o tratamento de erros global que está em api.tsx
// Este componente deve ser usado somente no arquivo api.tsx
//
// Para exibir mensagens de erros, faça da seguinte forma:
// const { successMessage, errorMessage } = useMessageSnackbarStore()
// successMessage("Vaga criada com sucesso!")
// errorMessage("Ocorreu um erro ao cadastrar uma nova vaga")
export function MessageSnackbar({ show, message, severity }: MessageSnackbarProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  useEffect(() => {
    if (show) {
      setIsOpen(true)
    }
  }, [show, message])

  function handleClose() {
    setIsOpen(false)
  }

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={6000}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      onClose={handleClose}
      sx={{
        maxWidth: "90%",
        whiteSpace: "pre-line",
      }}
    >
      <Alert
        severity={severity}
        onClose={handleClose}
        sx={{
          height: "100%",
          width: "100%",
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  )
}
