import CryptoJS from "crypto-js"

const secretKey = process.env.NEXT_PUBLIC_SECRET_KEY || "MSQu@l1f1c4Ym7X1"

export function encryptPassword(plainText: string): string {
  const key = CryptoJS.enc.Utf8.parse(secretKey)
  const iv = CryptoJS.enc.Utf8.parse(secretKey.substring(0, 16))
  const encrypted = CryptoJS.AES.encrypt(plainText, key, {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
  })
  return encrypted.toString()
}

export function decryptPassword(encryptedText: string): string {
  const key = CryptoJS.enc.Utf8.parse(secretKey)
  const iv = CryptoJS.enc.Utf8.parse(secretKey.substring(0, 16))
  const decrypted = CryptoJS.AES.decrypt(encryptedText, key, {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
  })
  return decrypted.toString(CryptoJS.enc.Utf8)
}
