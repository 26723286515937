import { create } from "zustand"

type Store = {
  loginModalIsOpen: boolean
  initialTab: number
  setLoginModalIsOpen: (value: boolean) => void
  setInitialTab: (tab: number) => void
}

export const useLoginModalStore = create<Store>((set) => ({
  loginModalIsOpen: false,
  initialTab: 0,
  setLoginModalIsOpen: (value) => set(() => ({ loginModalIsOpen: value })),
  setInitialTab: (tab) => set(() => ({ initialTab: tab })),
}))
