export enum IdentificationType {
  CPF = "CPF",
  CNPJ = "CNPJ",
  CEI = "CEI",
}

export function getIdentificationType(identificationType: string) {
  switch (identificationType) {
    case "CPF":
      return IdentificationType.CPF
    case "CNPJ":
      return IdentificationType.CNPJ
    case "CEI":
      return IdentificationType.CEI
    default:
      return IdentificationType.CNPJ
  }
}
