"use client"

import { MessageSnackbar } from "@/components/ui/message/MessageSnackbar"
import { getJwtToken, removeJwtToken } from "@/cookies/jwtCookieHelper"
import { ErrorResponse } from "@/interfaces/ErrorResponse"
import axios, { AxiosError } from "axios"
import React from "react"
import { Root, createRoot } from "react-dom/client"

export const api = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BASE_URL,
  headers: { "Access-Control-Allow-Origin": "*" },
})

// Interceptor de requisição para anexar o token JWT
api.interceptors.request.use((request) => {
  const token = getJwtToken()
  if (token) {
    request.headers["Authorization"] = `Bearer ${token}`
  }
  return request
})

// Interceptor de resposta para lidar com erros
api.interceptors.response.use(
  (response) => {
    return response
  },
  async (error: AxiosError) => {
    return await handleApiErrors(error)
  },
)

async function handleApiErrors(error: AxiosError) {
  if (error.response) {
    const status = error.response.status

    if (status === 401) {
      // Tentativa de renovação do token pode ser feita aqui no futuro
      removeJwtToken()
      showErrorMessage("Sua sessão expirou. Por favor, faça login novamente.")
    }

    if (status === 404) {
      error.response.data = null
    }

    const message = getErrorMessageFromResponseData(error.response.data as ErrorResponse)
    showErrorMessage(message)
  } else {
    showErrorMessage("Um erro inesperado ocorreu. Por favor, tente novamente.")
  }

  return Promise.reject(error)
}

export function getErrorMessageFromResponseData(responseData: ErrorResponse) {
  if (!responseData.invalidParams) return `${responseData.title}\n${responseData.detail}`

  const errors = responseData.invalidParams.map((e) => e.reason).join(", ")
  return `${responseData.title}: ${errors}`
}

let snackbarRoot: Root | null = null

export function showMessage(message: string, severity: "error" | "success") {
  const container = document.getElementById("message-spot")

  if (container) {
    if (!snackbarRoot) {
      snackbarRoot = createRoot(container)
    }

    snackbarRoot.render(
      <MessageSnackbar message={message} severity={severity} show={true} />,
    )
  }
}

export function showErrorMessage(message: string) {
  showMessage(message, "error")
}

export function showSuccessMessage(message: string) {
  showMessage(message, "success")
}

export const fetcher = (url: string) => api.get(url).then((response) => response.data)
