import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"

export function TermsUseContent() {
  return (
    <Box
      sx={{
        textAlign: "justify",
        px: { xs: 2, sm: 3, md: 4 },
        paddingRight: { sm: "1rem" },
      }}
    >
      <Typography
        variant="h6"
        component="h2"
        sx={{ fontSize: { xs: "1rem", sm: "1.25rem" } }}
      >
        1. Do direito à privacidade
      </Typography>
      <Typography paragraph sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
        O MS QUALIFICA DIGITAL reconhece a importância da segurança e da privacidade das
        informações inseridas no website{" "}
        <a href="https://msqualifica.ms.gov.br/">https://msqualifica.ms.gov.br/</a> e nos
        aplicativos Android e IOS. Cientes da preocupação dos usuários com o uso de seus
        dados pessoais, criamos esta Política de Segurança e Privacidade para assegurar
        total transparência no relacionamento com os usuários. Solicitamos que os usuários
        leiam atentamente para entender como obtemos e utilizamos seus dados.
      </Typography>
      <Typography paragraph sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
        Esta Política de Segurança e Privacidade esclarece como o MS Qualifica Digital
        tratará as informações dos usuários, podendo ser alterada a qualquer momento e sem
        aviso, para aprimorar e tornar as regras mais claras. Recomendamos a releitura
        periódica deste documento, que será disponibilizado no Website.
      </Typography>
      <Typography paragraph sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
        Conforme a Lei 13.709/2018 (Lei Geral de Proteção de Dados — LGPD), respeitamos a
        privacidade, direito garantido por lei, e esclarecemos de forma simples quais
        tipos de dados pessoais serão coletados, quando, de que forma e para quais
        finalidades.
      </Typography>

      <Typography
        variant="h6"
        component="h2"
        sx={{ fontSize: { xs: "1rem", sm: "1.25rem" } }}
      >
        2. Dos termos e definições
      </Typography>
      <Typography paragraph sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
        As informações fornecidas ao MS Qualifica Digital serão armazenadas de forma ética
        e legal, podendo ter um ou mais propósitos, previamente informados aos usuários.
        Coletamos informações pessoais no momento do cadastro, ao participar de promoções
        e programas do Governo do Estado de Mato Grosso do Sul, ao acessar o Website e por
        meio de Cookies. Também podemos combinar informações dos usuários com dados
        obtidos de parceiros comerciais ou de outras empresas.
      </Typography>

      <Typography
        variant="h6"
        component="h2"
        sx={{ fontSize: { xs: "1rem", sm: "1.25rem" } }}
      >
        3. Dos dados pessoais e sensíveis e do compartilhamento entre secretarias
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontSize: { xs: "0.75rem", sm: "1rem" } }}>
                Dados Tratados
              </TableCell>
              <TableCell sx={{ fontSize: { xs: "0.75rem", sm: "1rem" } }}>
                Compartilhamento com SINE
              </TableCell>
              <TableCell sx={{ fontSize: { xs: "0.75rem", sm: "1rem" } }}>
                Compartilhamento com SEAD
              </TableCell>
              <TableCell sx={{ fontSize: { xs: "0.75rem", sm: "1rem" } }}>
                Compartilhamento com Empregadores
              </TableCell>
              <TableCell sx={{ fontSize: { xs: "0.75rem", sm: "1rem" } }}>
                Compartilhamento com Instituição de Ensino
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[
              {
                data: "Nome Completo",
                sine: "Sim",
                sead: "Sim",
                empregadores: "Sim",
                instituicao: "Sim",
              },
              {
                data: "Nome Social",
                sine: "Sim",
                sead: "Não",
                empregadores:
                  "Sim (Se informado, o nome no currículo será o nome social)",
                instituicao: "Sim (Se informado, o nome no currículo será o nome social)",
              },
              {
                data: "CPF",
                sine: "Sim",
                sead: "Sim",
                empregadores: "Não",
                instituicao: "Sim",
              },
              {
                data: "Data de nascimento",
                sine: "Sim",
                sead: "Não",
                empregadores:
                  "Não (Informado a idade por contagem a partir da data de nascimento)",
                instituicao: "Sim",
              },
              {
                data: "Gênero",
                sine: "Sim",
                sead: "Não",
                empregadores: "Não",
                instituicao: "Não",
              },
              {
                data: "Estado civil",
                sine: "Sim",
                sead: "Não",
                empregadores: "Não",
                instituicao: "Não",
              },
              {
                data: "Raça",
                sine: "Sim",
                sead: "Não",
                empregadores: "Não",
                instituicao: "Não",
              },
              {
                data: "Nacionalidade",
                sine: "Sim",
                sead: "Não",
                empregadores: "Não",
                instituicao: "Não",
              },
              {
                data: "E-mail",
                sine: "Sim",
                sead: "Sim",
                empregadores: "Não",
                instituicao: "Não",
              },
              {
                data: "Telefone",
                sine: "Sim",
                sead: "Não",
                empregadores: "Não",
                instituicao: "Não",
              },
              {
                data: "Celular",
                sine: "Sim",
                sead: "Sim",
                empregadores: "Não",
                instituicao: "Não",
              },
              {
                data: "Papel na família",
                sine: "Sim",
                sead: "Não",
                empregadores: "Não",
                instituicao: "Não",
              },
              {
                data: "Renda própria",
                sine: "Sim",
                sead: "Não",
                empregadores: "Não",
                instituicao: "Não",
              },
              {
                data: "Renda familiar",
                sine: "Sim",
                sead: "Não",
                empregadores: "Não",
                instituicao: "Não",
              },
              {
                data: "Número de membros na família",
                sine: "Sim",
                sead: "Não",
                empregadores: "Não",
                instituicao: "Não",
              },
              {
                data: "Números de membros na família que trabalham",
                sine: "Sim",
                sead: "Não",
                empregadores: "Não",
                instituicao: "Não",
              },
              {
                data: "PCD (Pessoa com Deficiência)",
                sine: "Sim",
                sead: "Não",
                empregadores: "Não",
                instituicao: "Não",
              },
              {
                data: "Necessidade especial",
                sine: "Sim",
                sead: "Não",
                empregadores: "Não",
                instituicao: "Não",
              },
              {
                data: "CID (Classificação Internacional de Doenças)",
                sine: "Sim",
                sead: "Não",
                empregadores: "Não",
                instituicao: "Não",
              },
            ].map((row, index) => (
              <TableRow
                key={index}
                sx={{ backgroundColor: index % 2 ? "rgba(0, 0, 0, 0.04)" : "white" }}
              >
                <TableCell sx={{ fontSize: { xs: "0.75rem", sm: "1rem" } }}>
                  {row.data}
                </TableCell>
                <TableCell sx={{ fontSize: { xs: "0.75rem", sm: "1rem" } }}>
                  {row.sine}
                </TableCell>
                <TableCell sx={{ fontSize: { xs: "0.75rem", sm: "1rem" } }}>
                  {row.sead}
                </TableCell>
                <TableCell sx={{ fontSize: { xs: "0.75rem", sm: "1rem" } }}>
                  {row.empregadores}
                </TableCell>
                <TableCell sx={{ fontSize: { xs: "0.75rem", sm: "1rem" } }}>
                  {row.instituicao}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Typography
        variant="h6"
        component="h2"
        sx={{ fontSize: { xs: "1rem", sm: "1.25rem" } }}
      >
        4. Compartilhamento
      </Typography>
      <Typography paragraph sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
        O MS Qualifica Digital não compartilha dados pessoais dos usuários com outras
        pessoas ou empresas, exceto para fornecer produtos e serviços solicitados pelo
        usuário ou nas seguintes circunstâncias:
      </Typography>
      <Typography component="div" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
        <ul>
          <li>Mediante autorização expressa do usuário;</li>
          <li>Para atender ordens judiciais nos termos da legislação em vigor;</li>
          <li>
            Para investigar, prevenir ou adotar medidas relativas a atividades ilegais, ou
            suspeitas, ou para proteger a segurança física de qualquer pessoa.
          </li>
        </ul>
      </Typography>
      <Typography paragraph sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
        As informações genéricas e agregadas poderão ser fornecidas a parceiros
        comerciais, sempre sob acordos de confidencialidade e exigindo a adequação aos
        padrões de privacidade do MS Qualifica Digital.
      </Typography>

      <Typography
        variant="h6"
        component="h2"
        sx={{ fontSize: { xs: "1rem", sm: "1.25rem" } }}
      >
        5. Cookies
      </Typography>
      <Typography paragraph sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
        Cookies são pequenos arquivos de texto armazenados no computador do usuário para
        reconhecer e acompanhar sua navegação. Utilizamos cookies para facilitar o
        preenchimento de formulários, acompanhar promoções e proporcionar serviços
        personalizados. O usuário pode desativar os cookies alterando as configurações do
        navegador.
      </Typography>

      <Typography
        variant="h6"
        component="h2"
        sx={{ fontSize: { xs: "1rem", sm: "1.25rem" } }}
      >
        6. Atualização e veracidade dos dados
      </Typography>
      <Typography paragraph sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
        O titular é responsável pela atualização e veracidade dos dados informados ao MS
        Qualifica Digital. Não nos responsabilizamos por dados desatualizados ou pelo uso
        inadequado das informações.
      </Typography>

      <Typography
        variant="h6"
        component="h2"
        sx={{ fontSize: { xs: "1rem", sm: "1.25rem" } }}
      >
        7. Do prazo, confidencialidade e forma de armazenamento
      </Typography>
      <Typography paragraph sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
        Os dados do usuário serão obtidos mediante cadastro voluntário e armazenados
        seguramente. O acesso às informações é restrito a empregados autorizados, sendo
        obrigados a manter a confidencialidade das informações. O usuário pode editar suas
        informações ou cancelar sua conta a qualquer momento através do e-mail{" "}
        <a href="mailto:msqualifica@ms.gov.br">msqualifica@ms.gov.br</a>.
      </Typography>

      <Typography
        variant="h6"
        component="h2"
        sx={{ fontSize: { xs: "1rem", sm: "1.25rem" } }}
      >
        8. Da Segurança e Proteção dos Dados Pessoais
      </Typography>
      <Typography paragraph sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
        As informações são protegidas por padrões de segurança e confidencialidade,
        incluindo criptografia, certificações digitais e acessos controlados. Recomendamos
        cuidados adicionais aos usuários, como não compartilhar senhas e desconectar-se ao
        finalizar o uso do Website.
      </Typography>
      <Typography paragraph sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
        8.1. Cuidados com Golpes: Os criminosos cibernéticos se aproveitam dos assuntos do
        momento para enviar mensagens fraudulentas com intuito de roubar dados ou instalar
        vírus e outros softwares maliciosos por meio de links em mensagens falsas. Por
        assegurar uma comunicação eficiente e ágil, o e-mail passa a ser uma ferramenta
        fundamental para comunicação entre o MS Qualifica Digital e seus Usuários.
        Entretanto, o MS Qualifica Digital compromete-se a não enviar “spam”,
        malas-diretas ou e-mails de qualquer natureza sem o consentimento prévio e
        expresso do Usuário. O Usuário sempre terá a liberdade de decidir pelo recebimento
        de informações ou pelo cancelamento deste a qualquer momento. Não nos
        responsabilizamos, no entanto, pelo tráfego de informações confidenciais por meio
        de canais de e-mail. O MS Qualifica Digital não envia comunicados automáticos
        sobre alterações no cadastro ou solicitações para troca de senha, a não ser nos
        casos em que o próprio o candidato recorra a procedimento específico para
        recuperação de senha, o qual se encontra disponível no Portal do Candidato. Avisos
        em formato SMS não são utilizados como forma de comunicação pelo MS Qualifica
        Digital.
      </Typography>
      <Typography paragraph sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
        8.2. Compartilhamento de senhas: as informações sobre a conta dos Usuários são
        protegidas por senha, para que somente o Usuário tenha acesso às suas informações.
        O MS Qualifica Digital recomenda que o Usuário não revele sua senha a terceiros,
        bem como que desconecte sua conta ou feche a janela do navegador ao concluir suas
        atividades no Website. O MS Qualifica Digital nunca solicitará, seja por telefone
        ou por e-mail, a senha pessoal do Usuário. Sua senha é pessoal e intransferível e
        deve ser mantida sob sigilo e em ambiente seguro. Não compartilhe a sua senha,
        ceder ou utilizar a senha de outra pessoa é tipificado como crime no art. 308, do
        Código Penal.
      </Typography>
      <Typography paragraph sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
        8.3. Acesso não autorizado ao seu computador, conta ou senha: se certifique de
        sempre clicar em “sair” ao encerrar sua navegação.
      </Typography>

      <Typography
        variant="h6"
        component="h2"
        sx={{ fontSize: { xs: "1rem", sm: "1.25rem" } }}
      >
        9. Direitos do Titular de Dados e como exercê-los
      </Typography>
      <Typography paragraph sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
        Conforme Lei 13.709/2018, o titular dos dados:
      </Typography>
      <Typography component="div" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
        <ul>
          <li>
            tem o livre direito de acesso aos dados e corrigi-los se assim optar por tal
            alteração;
          </li>
          <li>
            solicitar a confirmação da existência de tratamento de dados por parte do
            controlador;
          </li>
          <li>
            solicitar a exclusão, anonimização, bloqueio de dados excessivos ou tratados
            em desconformidade com a LGPD;
          </li>
          <li>
            solicitar ao controlador com quem os dados foram compartilhados e quais dados
            foram;
          </li>
          <li>
            recusar a fornecer o dado, porém deve estar ciente que o não fornecimento
            impede o aproveitamento e inviabiliza alguma atividade fornecida pelo
            controlador;
          </li>
          <li>revogar o consentimento dos dados pessoais.</li>
        </ul>
      </Typography>
      <Typography paragraph sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
        Para mais informações acerca da LGPD acesse:{" "}
        <a
          target="_blank"
          href="https://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/l13709.htm"
        >
          https://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/l13709.htm
        </a>
      </Typography>

      <Typography
        variant="h6"
        component="h2"
        sx={{ fontSize: { xs: "1rem", sm: "1.25rem" } }}
      >
        10. Disposições Finais
      </Typography>
      <Typography paragraph sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
        As disposições constantes deste Termo de Privacidade estão sujeitas à melhoria e
        ao aprimoramento contínuo e podem ser atualizadas ou modificadas a qualquer
        momento, unilateralmente ou em razão de lei. Cabendo ao usuário, ou a seu
        responsável legal, caso seja menor de idade, o dever de ciência de seu teor no
        momento do acesso aos sites e plataformas de sistemas da instituição. É
        recomendado seja realizada a leitura periódica deste Termo, para fins de
        atualização e conhecimento de direitos e obrigações. As empresas cadastradas
        deverão, obrigatoriamente, respeitar as regras estipuladas nesse Termo de
        Privacidade, nas políticas internas de Tecnologia da Informação e Comunicação e
        nas demais leis vigentes.
      </Typography>

      <Typography
        variant="h6"
        component="h2"
        sx={{ fontSize: { xs: "1rem", sm: "1.25rem" } }}
      >
        11. Última Atualização: 10/07/2024
      </Typography>
    </Box>
  )
}
