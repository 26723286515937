import { Box, Typography } from "@mui/material"
import Link from "next/link"

export function PrivacyContent() {
  return (
    <Box sx={{ px: { xs: 2, sm: 3, md: 4 } }}>
      <Typography paragraph sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
        No MS Qualifica Digital, reconhecemos a importância da privacidade e da segurança
        das informações que você nos confia. Este documento explica como tratamos os dados
        pessoais dos usuários do nosso website msqualifica.ms.gov.br e dos aplicativos
        para Android e iOS. Compreendemos suas preocupações com o uso de seus dados
        pessoais e estamos comprometidos em protegê-los. Portanto, encorajamos você a ler
        esta Política de Privacidade cuidadosamente.
      </Typography>
      <Typography
        variant="h6"
        component="h2"
        sx={{ fontSize: { xs: "1rem", sm: "1.25rem" } }}
      >
        Alterações na Política
      </Typography>
      <Typography paragraph sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
        Esta Política de Privacidade pode ser atualizada periodicamente para refletir
        novas práticas ou mudanças regulatórias. Recomendamos que você reveja esta
        política regularmente para se manter informado sobre como estamos protegendo suas
        informações. Qualquer alteração será efetiva imediatamente após a publicação da
        versão revisada no Website.
      </Typography>
      <Typography
        variant="h6"
        component="h2"
        sx={{ fontSize: { xs: "1rem", sm: "1.25rem" } }}
      >
        Coleta de Informações
      </Typography>
      <Typography paragraph sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
        Cadastro e Registro: Coletamos uma variedade de informações pessoais diretamente
        de você durante o processo de cadastro e registro no Website. Essas informações
        incluem:
      </Typography>
      <Typography paragraph sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
        Identificação Pessoal: Nome, nome social, e CPF.
      </Typography>
      <Typography paragraph sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
        Contato: Endereço de e-mail e número de celular.
      </Typography>
      <Typography paragraph sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
        Dados Demográficos: Data de nascimento, gênero, estado civil e nacionalidade.
      </Typography>
      <Typography paragraph sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
        Informações Socioeconômicas: Rendas e dados familiares.
      </Typography>
      <Typography paragraph sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
        Saúde: Informações específicas relacionadas à sua saúde, conforme aplicável.
      </Typography>
      <Typography paragraph sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
        Esses dados são coletados para fornecer serviços personalizados, melhorar a
        experiência do usuário e atender aos requisitos legais e regulatórios.
      </Typography>
      <Typography
        variant="h6"
        component="h2"
        sx={{ fontSize: { xs: "1rem", sm: "1.25rem" } }}
      >
        Uso do Website
      </Typography>
      <Typography paragraph sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
        Coletamos informações automaticamente de seu computador ou dispositivo, incluindo
        endereço IP, dados de cookies, e detalhes de hardware e software, para melhorar
        sua experiência de navegação.
      </Typography>
      <Typography paragraph sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
        Interações com o Website: Informações podem ser coletadas quando você participa do
        programa voucher qualificação ou microcrédito para microempreendedores.
      </Typography>
      <Typography
        variant="h6"
        component="h2"
        sx={{ fontSize: { xs: "1rem", sm: "1.25rem" } }}
      >
        Uso das Informações
      </Typography>
      <Typography paragraph sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
        Utilizamos suas informações para:
      </Typography>
      <Typography component="div">
        <ul style={{ fontSize: "0.875rem" }}>
          <li>
            Conectar com o mercado de trabalho, ofertar vouchers de qualificação e
            microcrédito.
          </li>
          <li>Melhorar e personalizar a experiência no Website.</li>
          <li>Conduzir pesquisas e análises para aprimorar nosso serviço.</li>
          <li>Comunicar novidades e ofertas, sujeitas ao seu consentimento.</li>
        </ul>
      </Typography>
      <Typography
        variant="h6"
        component="h2"
        sx={{ fontSize: { xs: "1rem", sm: "1.25rem" } }}
      >
        Compartilhamento de Informações
      </Typography>
      <Typography paragraph sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
        Com Autorização: Compartilhamos suas informações com o SINE.
      </Typography>
      <Typography paragraph sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
        Requisitos Legais: Podemos divulgar suas informações se necessário para cumprir
        com ordens judiciais ou leis aplicáveis.
      </Typography>
      <Typography paragraph sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
        Parceiros Comerciais: Compartilhamos informações agregadas e não identificáveis
        com parceiros confiáveis, sob rigorosos acordos de confidencialidade, para ajudar
        em nossas operações comerciais.
      </Typography>
      <Typography
        variant="h6"
        component="h2"
        sx={{ fontSize: { xs: "1rem", sm: "1.25rem" } }}
      >
        Cookies
      </Typography>
      <Typography paragraph sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
        Utilizamos cookies para melhorar a funcionalidade do Website, facilitando a
        navegação e personalização.
      </Typography>
      <Typography paragraph sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
        Você pode configurar seu navegador para bloquear ou alertar sobre cookies, mas
        isso pode afetar a disponibilidade de alguns recursos.
      </Typography>
      <Typography
        variant="h6"
        component="h2"
        sx={{ fontSize: { xs: "1rem", sm: "1.25rem" } }}
      >
        Segurança e Confidencialidade
      </Typography>
      <Typography paragraph sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
        Proteção de Dados: Implementamos medidas de segurança robustas para proteger suas
        informações contra acesso não autorizado, alteração, divulgação ou destruição.
      </Typography>
      <Typography paragraph sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
        Acesso Restrito: Apenas funcionários autorizados têm acesso a informações
        pessoais, e eles são obrigados a manter a confidencialidade das informações.
      </Typography>
      <Typography paragraph sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
        Senhas e Segurança: Aconselhamos que mantenha sua senha em segredo e sempre
        desconecte sua conta após o uso para evitar acesso não autorizado.
      </Typography>
      <Typography
        variant="h6"
        component="h2"
        sx={{ fontSize: { xs: "1rem", sm: "1.25rem" } }}
      >
        Seus Direitos
      </Typography>
      <Typography paragraph sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
        Você pode acessar, corrigir ou deletar suas informações pessoais a qualquer
        momento.
      </Typography>
      <Typography paragraph sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
        Pode optar por não receber comunicações de marketing.
      </Typography>
      <Typography paragraph sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
        Tem o direito de cancelar sua conta e remover seu perfil.
      </Typography>
      <Typography
        variant="h6"
        component="h2"
        sx={{ fontSize: { xs: "1rem", sm: "1.25rem" } }}
      >
        Contato
      </Typography>
      <Typography paragraph sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
        Se tiver dúvidas ou preocupações sobre nossa Política de Privacidade, ou se deseja
        exercer seus direitos relacionados aos dados pessoais, entre em contato conosco
        através do e-mail{" "}
        <Link href="mailto:msqualifica@ms.gov.br">msqualifica@ms.gov.br</Link>.
      </Typography>
      <Typography paragraph sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
        Esta Política de Privacidade é um compromisso do MS Qualifica Digital com sua
        privacidade e segurança online.
      </Typography>
    </Box>
  )
}
