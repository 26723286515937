import { Stack } from "@mui/material"
import * as React from "react"

interface AccessModalContentProps {
  children: React.ReactNode
  banner: React.ReactNode
  reversed?: boolean
}
export function AccessModalContent({
  children,
  banner,
  reversed = false,
}: AccessModalContentProps) {
  return (
    <Stack
      direction={{
        xs: "column",
        md: reversed ? "row-reverse" : "row",
      }}
      width={"100%"}
      height={"100%"}
      justifyContent={"space-around"}
      p={3}
      spacing={3}
    >
      <Stack
        alignItems={"center"}
        justifyContent={"center"}
        direction={{
          xs: "column",
          md: reversed ? "row-reverse" : "row",
        }}
        height={{
          xs: "40%",
          md: "100%",
        }}
      >
        {banner}
      </Stack>

      <Stack
        width={{
          xs: "100%",
          md: "50%",
        }}
        height={"100%"}
        alignItems={"center"}
        justifyContent={{
          xs: "flex-start",
          md: "center",
        }}
        spacing={5}
      >
        {children}
      </Stack>
    </Stack>
  )
}
