import { ModalComponent } from "@/components/ui/modal/Modal"
import { AccessModalBodyText } from "@/containers/person/login/AccessModalBodyText"
import { AccessModalContent } from "@/containers/person/login/AccessModalContent"
import { AccessModalTitleText } from "@/containers/person/login/AccessModalTitleText"
import { useLoginModalStore } from "@/stores/ui/useLoginModalStore"
import { usePersonModalStore } from "@/stores/ui/usePersonModalStore"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import { Button } from "@mui/material"
import * as React from "react"

interface CreateYourFreeAccountProps {
  banner: React.ReactNode
}

export function CreateYourFreeAccount({ banner }: CreateYourFreeAccountProps) {
  const { setPersonModalIsOpen } = usePersonModalStore()
  const { setLoginModalIsOpen } = useLoginModalStore()
  return (
    <ModalComponent.Content hasActionButton={false} hasBackButton={false}>
      <AccessModalContent banner={banner}>
        <AccessModalTitleText text={"Vamos começar?"} />
        <AccessModalBodyText
          text={
            "Crie sua conta e encontre oportunidades para empregabilidade e qualificação profissional."
          }
        />

        <Button
          color={"primary"}
          variant={"contained"}
          endIcon={<ChevronRightIcon />}
          onClick={() => {
            setPersonModalIsOpen(true)
            setLoginModalIsOpen(false)
          }}
        >
          {"CRIAR MEU CADASTRO"}
        </Button>
      </AccessModalContent>
    </ModalComponent.Content>
  )
}
