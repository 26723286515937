import { removeJwtToken, setJwtToken } from "@/cookies/jwtCookieHelper"
import { LoginInput } from "@/interfaces/login/LoginInput"
import { LoginOutput } from "@/interfaces/login/LoginOutput"
import { api } from "@/libs/api"
import { getErrorMessageFromResponseData, showErrorMessage } from "@/libs/api"
import { AxiosError } from "axios"

export async function loginService(loginInput: LoginInput) {
  try {
    removeJwtToken()
    const response = await api.post<LoginOutput>("/user/login", loginInput)
    if (response.status === 200) {
      setJwtToken(response.data.accessToken)
    }

    return { ...response.data }
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      const message = getErrorMessageFromResponseData(error.response.data)
      showErrorMessage(message)
    } else {
      showErrorMessage("Um erro inesperado ocorreu. Por favor tente novamente.")
    }
  }
}
