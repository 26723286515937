import { CloseOutlined } from "@mui/icons-material"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import { Box, Button, Stack, SxProps } from "@mui/material"
import * as React from "react"

interface ModalContentProps {
  topChildren?: React.ReactNode
  children?: React.ReactNode
  hasBackButton?: boolean
  handleBackButton?: () => void
  hasActionButton?: boolean
  handleActionButton?: () => void
  actionButtonText?: string
  backButtonText?: string
  sx?: SxProps
}

export function ModalContent({
  topChildren,
  children,
  hasBackButton = true,
  handleBackButton,
  hasActionButton = true,
  handleActionButton,
  actionButtonText = "SALVAR E CONTINUAR",
  backButtonText = "FECHAR",
  sx,
}: ModalContentProps) {
  return (
    <Stack
      sx={{
        flex: 1,
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        padding: 2,
        overflow: "auto",
        ...sx,
      }}
    >
      {hasBackButton && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            color: "GrayText",
          }}
        >
          {topChildren}
          <Button
            startIcon={
              backButtonText === "FECHAR" ? <CloseOutlined /> : <ChevronLeftIcon />
            }
            onClick={handleBackButton}
            color="inherit"
          >
            {backButtonText}
          </Button>
        </Box>
      )}

      <Box flex={1} overflow={"auto"} borderRadius={"inherit"} marginY={2}>
        {children}
      </Box>

      {hasActionButton && (
        <Box sx={{ marginLeft: "auto" }}>
          <Button
            id="btn-modal-action-button"
            variant="outlined"
            endIcon={<ChevronRightIcon />}
            onClick={handleActionButton}
          >
            {actionButtonText}
          </Button>
        </Box>
      )}
    </Stack>
  )
}
