import * as React from "react"
import { Dispatch, SetStateAction } from "react"
import ReCAPTCHA from "react-google-recaptcha"

interface RecaptchaProps {
  captchaIsDone: boolean
  setCaptchaIsDone: Dispatch<SetStateAction<boolean>>
}

export function Recaptcha({ captchaIsDone, setCaptchaIsDone }: RecaptchaProps) {
  function getSitekey(): string {
    return process.env.NEXT_PUBLIC_RECAPTCHA_KEY || ""
  }

  return (
    <ReCAPTCHA sitekey={getSitekey()} onChange={() => setCaptchaIsDone(!captchaIsDone)} />
  )
}
